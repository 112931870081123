<template>
  <div class="detailBox" v-loading="tableLoading" element-loading-text="拼命加载中...">
    <div class="statusTag">
      <span v-if="detailObj.status == 0"><el-tag type="warning">待处理</el-tag></span>
      <span v-if="detailObj.status == 1"><el-tag type="success">同意退卡</el-tag></span>
      <span v-if="detailObj.status == 2"><el-tag type="info">取消退卡</el-tag></span>
    </div>
    <!-- 客户提交信息 -->
    <div class="memberSubmitBox">
      <el-card>
        <div class="title">客户提交信息</div>
        <div class="items flex">
          <div class="item flex items-center">
            <div class="label">申请编码：</div>
            <div><el-input v-model="detailObj.applyCode" placeholder="申请编码" disabled></el-input></div>
          </div>
          <div class="item flex items-center">
            <div class="label">提交时间：</div>
            <div><el-input v-model="detailObj.creationTime" placeholder="提交时间" disabled></el-input></div>
          </div>
          <div class="item flex items-center">
            <div class="label">联系姓名：</div>
            <div><el-input v-model="detailObj.contactName" placeholder="联系姓名" disabled></el-input></div>
          </div>
          <div class="item flex items-center">
            <div class="label">联系手机号：</div>
            <div><el-input v-model="detailObj.contactMobile" placeholder="联系手机号" disabled></el-input></div>
          </div>
        </div>
        <div class="items flex">
          <div class="item flex items-center">
            <div class="label">退卡原因：</div>
            <div><el-input v-model="returnReason" placeholder="退卡原因" disabled></el-input></div>
          </div>
          <div class="item flex items-center" v-if="detailObj.reason != 1">
            <div class="label">门店：</div>
            <div><el-input v-model="detailObj.shopName" placeholder="门店" disabled></el-input></div>
          </div>
        </div>
        <!-- 订单 -->
        <el-row v-if="detailObj.reason == 3">
          <el-col :span="24" class="flex">
            <div class="label">订单：</div>
            <div class="w-full">
              <el-row class="rowTitle">
                <el-col :span="4">卡名称</el-col>
                <el-col :span="4">持卡ID</el-col>
                <el-col :span="2">卡状态</el-col>
                <el-col :span="2">持卡来源</el-col>
                <el-col :span="3">剩余卡金</el-col>
                <el-col :span="2">业务状态</el-col>
                <el-col :span="4">订单号</el-col>
                <el-col :span="3">交易时间</el-col>
              </el-row>
              <el-row class="rowList">
                <el-col :span="4">{{ detailObj.buyOrder.name }}</el-col>
                <el-col :span="4">{{ detailObj.buyOrder.cardId }}</el-col>
                <el-col :span="2">{{ detailObj.buyOrder.cardStatus }}</el-col>
                <el-col :span="2">{{ detailObj.buyOrder.dataSource }}</el-col>
                <el-col :span="3">{{ detailObj.buyOrder.balance }}</el-col>
                <el-col :span="2">{{ detailObj.buyOrder.usable }}</el-col>
                <el-col :span="4">{{ detailObj.buyOrder.tradeId }}</el-col>
                <el-col :span="3">{{ detailObj.buyOrder.tradeTime }}</el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <!-- 服务单 -->
        <el-row class="mt20" v-if="detailObj.reason == 2 || detailObj.reason == 4">
          <el-col :span="24" class="flex">
            <div class="label">服务单：</div>
            <div class="w-full">
              <el-row class="rowTitle">
                <el-col :span="3">服务单批次号</el-col>
                <el-col :span="3">服务时间</el-col>
                <el-col :span="3">服务项目</el-col>
                <el-col :span="3">服务单状态</el-col>
                <el-col :span="2">划卡次数</el-col>
                <el-col :span="3">服务单ID</el-col>
                <el-col :span="2">服务人员</el-col>
                <el-col :span="2">被服务人</el-col>
                <el-col :span="2">是否删单</el-col>
              </el-row>
              <el-row class="rowList" v-for="item in detailObj.serviceOrders" :key="item.batchId">
                <el-col :span="3">{{ item.batchId }}</el-col>
                <el-col :span="3">{{ item.timeEnd }}</el-col>
                <el-col :span="3">{{ item.mcardItemName }}</el-col>
                <el-col :span="3">{{ item.status }}</el-col>
                <el-col :span="2">{{ item.quantity }}</el-col>
                <el-col :span="3">{{ item.id }}</el-col>
                <el-col :span="2">{{ item.staffName }}</el-col>
                <el-col :span="2">{{ item.mname }}</el-col>
                <el-col :span="2">{{ item.isDel }}</el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!-- 客户信息 -->
    <div class="memberSubmitBox mt20">
      <el-card>
        <div class="title">客户信息</div>
        <el-row>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">客户姓名：</div>
              <div>{{ detailObj.memberName }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">客户ID：</div>
              <div div>{{ detailObj.memberId }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">客户手机号：</div>
              <div>{{ detailObj.memberPhone }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">新老客：</div>
              <div>{{ detailObj.isNew == 1 ? '新客' : '老客' }}</div>
            </div>
          </el-col>
        </el-row>
        
        <el-row>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">会员等级：</div>
              <div>{{ detailObj.memberGradeName }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">开卡金额：</div>
              <div>{{ detailObj.totalCardAmout }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">已耗卡总额：</div>
              <div>{{ detailObj.totalConsumeAmount }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center">
              <div class="label">卡项余额：</div>
              <div>{{ detailObj.balance }}</div>
            </div>
          </el-col>
        </el-row>
        
        <el-row>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.firstTradeIsDel == 1 }">
              <div class="label">首次消费时间：</div>
              <div>{{ detailObj.firstConsumeDateTime }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.firstTradeIsDel == 1 }">
              <div class="label">首次消费大区：</div>
              <div>{{ detailObj.firstConsumeLargeArea }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.firstTradeIsDel == 1 }">
              <div class="label">首次消费区域：</div>
              <div>{{ detailObj.firstConsumeSmallArea }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.firstTradeIsDel == 1 }">
              <div class="label">首次消费城市：</div>
              <div>{{ detailObj.firstConsumeCity }}</div>
            </div>
          </el-col>
        </el-row>
        
        <el-row>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.firstTradeIsDel == 1 }">
              <div class="label">首次消费门店：</div>
              <div>{{ detailObj.firstConsumeShopName }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.firstTradeIsDel == 1 }">
              <div class="label">首次消费金额：</div>
              <div>{{ detailObj.firstConsumeAmount }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.firstTradeIsDel == 1 }">
              <div class="label">首次消费项目：</div>
              <div class="overflow">{{ detailObj.firstConsumeItem }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.lastTradeIsDel == 1 }">
              <div class="label">最近一次消费城市：</div>
              <div>{{ detailObj.lastConsumeCity || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.lastTradeIsDel == 1 }">
              <div class="label">最近一次消费门店：</div>
              <div>{{ detailObj.lastConsumeShopName || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item flex items-center" :class="{ 'textDel': detailObj.lastTradeIsDel == 1 }">
              <div class="label">最近一次消费项目：</div>
              <div>{{ detailObj.lastConsumeItem || '-' }}</div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <!-- 处理 -->
    <div class="memberSubmitBox mt20">
      <el-card>
        <div class="title">处理</div>
        <div class="item flex">
          <div class="label"><span style="color: red;">*</span> 备注：</div>
          <div style="width: 100%;"><el-input v-model.trim="detailObj.remark" :disabled="returnType == 'look' || detailObj.status != 0" :rows="6" type="textarea" placeholder="请输入备注" :maxlength="500" show-word-limit /></div>
        </div>
        <div class="buttonBox mt40 flex justify-center">
          <el-button @click="backFun">返回</el-button>
          <el-button @click="showDialogFun('cancel')" v-if="returnType == 'deal' && detailObj.status == 0">取消</el-button>
          <el-button type="primary" @click="showDialogFun('pass')" v-if="returnType == 'deal' && detailObj.status == 0">通过</el-button>
        </div>
      </el-card>
    </div>
    <!-- 通过 / 取消 -->
    <el-dialog v-model="dialogVisible" title="温馨提示" :width="500">
      <span style="color: #333;font-size: 16px;">{{ `是否确定${dialogTitle}该退卡信息？` }}</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="passOrder" :loading="passLoading">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup name="CardDetail">
  import { ref, reactive, onMounted, computed } from 'vue'
  import { ElMessage } from "element-plus"
  import { returnDetail, passReturn, rejReturn } from "@/http/returnCard"
  import { useRoute, useRouter } from 'vue-router'
  const route = useRoute()
  const router = useRouter()

  let returnId = route.query.id
  let returnType = route.query.type
  onMounted(() => {
    getDetail(returnId)
  })
  // 获取详情
  let tableLoading = ref(false)
  let detailObj = ref({})
  function getDetail(id) {
    let params = { id: id }
    tableLoading.value = true
    returnDetail(params).then(res => {
      tableLoading.value = false
      if (res.code == 200) {
        detailObj.value = res.data
        checkReason(detailObj.value.reason)
      } else ElMessage.error({
        message: res.message,
        duration: 2000
      })
    }).catch(() => {
      tableLoading.value = false
    })
  }
  // 处理退卡原因
  let returnReason = ref('')
  let reasonList = ref([
    { key: '个人原因', value: 1 },
    { key: '服务不佳', value: 2 },
    { key: '产品或项目问题', value: 3 },
    { key: '未达到预期效果', value: 4 }
  ])
  function checkReason(val) {
    reasonList.value.forEach(item => {
      if (item.value == val) {
        returnReason.value = item.key
      }
    })
  }
  // 通过/取消/返回 退卡
  function backFun() {
    router.push('/ReturnCard')
  }
  let dialogVisible = ref(false)
  let dialogTitle = ref('')
  let passLoading = ref(false)
  function showDialogFun(type) {
    if(!detailObj.value.remark) return ElMessage.warning({
      message: '备注信息不能为空',
      duration: 2000
    })
    dialogVisible.value = true
    type == 'pass' ? dialogTitle.value = '通过' : dialogTitle.value = '取消'
  }
  function passOrder() {
    let data = {
      id: returnId,
      remark: detailObj.value.remark
    }
    passLoading.value = true
    if (dialogTitle.value == '通过') { // 通过
      passReturn(data).then(res => {
        passLoading.value = false
        dialogVisible.value = false
        ElMessage.success({
          message: '提交成功',
          duration: 2000
        })
        getDetail(returnId)
      }).catch(() => {
        passLoading.value = false
      })
    } else { // 取消
      rejReturn(data).then(res => {
        passLoading.value = false
        dialogVisible.value = false
        ElMessage.success({
          message: '提交成功',
          duration: 2000
        })
        getDetail(returnId)
      }).catch(() => {
        passLoading.value = false
      })
    }
  }
</script>

<style lang="scss" scoped>
.detailBox {
  position: relative;
  .textDel {
    text-decoration: line-through !important;
  }
  .statusTag {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .ml30 {
    margin-left: 30px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  .memberSubmitBox {
    .title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .items {
      margin-bottom: 20px;
    }
    .item {
      margin-right: 10px;
      font-size: 14px;
      line-height: 26px;
    }
    .label {
      width: 150px;
      text-align: right;
      flex: 0 0 auto;
      font-size: 14px;
    }
    .rowTitle {
      border: 1px solid #ebeef5;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 600;
      color: #000;
      background: rgb(242, 242, 242);
    }
    .rowList {
      border: 1px solid #ebeef5;
      border-top: none;
      padding: 10px 20px;
      font-size: 14px;
      color: #333;
    }
  }
}
</style>